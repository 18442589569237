<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">商家统计</div>
        <el-button @click="handleExport" v-permission="['商家统计导出']">导出</el-button>
      </div>
      <div class="search">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
        <el-input v-model="discount_key" type="text" placeholder="请输入云闪付活动名称 | 活动号" clearable></el-input>
        <div class="wrap">
          <el-input v-model="business_name" type="text" placeholder="请输入商家名称" clearable></el-input>
          <el-input v-model="mchnt_cd" type="text" placeholder="请输入商家号" clearable></el-input>
          <el-button @click="pageEmit(1)">搜索</el-button>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <el-table :data="tableData.items" v-loading="loading" @sort-change="sortChange">
      <el-table-column type="expand">
        <template #default="scope">
          <div class="merchant-expand" v-for="item in scope.row.business" :key="item.id">
            <img :src="item.logo_img?.url || defaultLogo" alt="" />
            <div>
              <div class="row">商家名：{{ item.name }}</div>
              <div class="row">商家地址：{{ item.address }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mchnt_cd" label="商家号" width="180"></el-table-column>
      <el-table-column prop="coupon_used_total" label="核销优惠券数量" sortable="custom"></el-table-column>
      <el-table-column label="用户消费总额">
        <template #default="scope">
          {{ (Number(scope.row.order_at_total) - Number(scope.row.discount_at_total)).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column prop="discount_at_total" label="优惠券抵扣总额" sortable="custom"></el-table-column>
      <el-table-column prop="order_at_total" label="订单金额" sortable="custom"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import commonHandle from '@/hooks/useCommon'
import util from '@/lin/util/util'
import { ElMessage } from 'element-plus/lib/components'
import statisticsModel from '@/model/statistics'
import defaultLogo from '@/assets/image/default-logo.png'

export default {
  setup() {
    const discount_key = ref()
    const business_name = ref()
    const mchnt_cd = ref()
    const sort_column = ref()
    const direction = ref()
    const time = ref()

    const getData = async pageQuery => {
      if (mchnt_cd.value && !util.checkLength(mchnt_cd.value, 3)) return ElMessage.warning('商家号请至少输入3个字符')
      if (discount_key.value && !util.checkLength(discount_key.value, 2)) return ElMessage.warning('云闪付活动名称或活动号至少输入2个字符')

      loading.value = true
      const res = await statisticsModel.getMerchantStastic({
        page: pageQuery?.page || pageParam.currentPage - 1,
        count: pageQuery?.count || pageParam.count,
        discount_key: discount_key.value || null,
        business_name: business_name.value || null,
        mchnt_cd: mchnt_cd.value || null,
        sort_column: sort_column.value || null,
        direction: direction.value || null,
        start: time.value ? time.value[0] : null,
        end: time.value ? time.value[1] : null,
      })
      loading.value = false
      tableData.value = res
      return res
    }

    // 排序
    const sortChange = col => {
      sort_column.value = col.prop
      direction.value = col.prop ? (col.order == 'ascending' ? 'asc' : 'desc') : null
      getData()
    }

    // 导出
    const handleExport = async () => {
      const res = await statisticsModel.merStatisticExport({
        discount_key: discount_key.value || null,
        business_name: business_name.value || null,
        mchnt_cd: mchnt_cd.value || null,
      })
      console.log(res)
      let list = []
      const tHeader = ['商家号', '商家名称', '核销优惠券数量', '用户消费总额', '优惠券抵扣总额', '用户实际支付总额']
      const filterVal = [
        'mchnt_cd',
        'business_name',
        'coupon_used_total',
        'actual_pay_total',
        'discount_at_total',
        'order_at_total',
      ]
      list = res.map(item => {
        item.coupon_used_total = Number(item.coupon_used_total)
        item.discount_at_total = Number(item.discount_at_total)
        item.order_at_total = Number(item.order_at_total)
        return {
          ...item,
          business_name: item.business ? item.business.name : '未知商家',
          actual_pay_total: Number((Number(item.order_at_total) - Number(item.discount_at_total)).toFixed(2)),
        }
      })
      const data = list.map(v => filterVal.map(j => v[j]))
      util.exportExcel(tHeader, data, '商家统计数据')
    }

    const { tableData, loading, pageParam, pageEmit } = commonHandle(getData)

    onMounted(() => {
      getData()
    })

    return {
      discount_key,
      business_name,
      mchnt_cd,
      time,
      defaultLogo,
      tableData,
      loading,
      pageParam,
      pageEmit,
      handleExport,
      sortChange,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
.merchant-expand {
  margin-left: 50px;
  margin-bottom: 30px;
  @include flex-align;
  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }
  .row {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
</style>
